import {
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { Design } from '@senrasystems/senra-ui';
import {
  useCloneDesignMutation,
  useCreateDesignMutation,
  useDeleteDesignMutation,
  useDesignsQuery,
  useUpdateDesignMutation,
} from '@web/apps/Design/api/designs-api.ts';
import { Extension, useExportDesignMutation } from '@web/apps/Design/api/export-api.ts';
import FilterBox from '@web/components/FilterBox.tsx';
import Loading from '@web/components/Loading.tsx';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';
import { MouseEvent, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import Title from '../../../components/Title.tsx';
import { DesignActions } from './DesignActions.tsx';
import DesignModal, { DesignFormData } from './DesignModal.tsx';
import { PartRevisionLabel } from './PartRevisionLabel.tsx';

/**
 * Component that displays the list of Designs.
 * @constructor
 */
const Designs = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';
  const debouncedQuery = useDebouncedValue(query, 500);
  const { isLoading, data: designs, error } = useDesignsQuery(debouncedQuery);
  const [selectedDesign, setSelectedDesign] = useState<DesignFormData>();

  // State for the design modal
  const { isOpen: isCreateModalOpen, onOpen: openCreateModal, onClose: closeCreateModal } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const { isOpen: isCloneModalOpen, onOpen: openCloneModal, onClose: closeCloneModal } = useDisclosure();

  // Mutations for creating, updating, cloning, and deleting designs
  const { mutate: createDesign, isPending: createDesignPending } = useCreateDesignMutation();
  const { mutate: updateDesign } = useUpdateDesignMutation();
  const { mutate: cloneDesign } = useCloneDesignMutation();
  const { mutate: exportDesign } = useExportDesignMutation();
  const { mutate: deleteDesign } = useDeleteDesignMutation();

  const [spacing20, spacing40, spacing80] = useToken('space', ['20', '40', '80']);

  if (error) return;

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('q', value);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
  };

  const handleOpenDesign = (design: Design) => {
    navigate(generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id }));
  };

  const handleCreateDesign = (data: DesignFormData) => {
    createDesign({
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        tenantId: data.tenant.id,
      },
    });
  };

  const handleUpdateDesign = (data: DesignFormData) => {
    updateDesign({
      designId: selectedDesign?.id || '',
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        locked: !!data.lockedAt,
      },
    });
  };

  const handleCloneDesign = (data: DesignFormData) => {
    cloneDesign({
      designId: selectedDesign?.id || '',
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        measurementMode: data.measurementMode,
        tenantId: data.tenant.id,
      },
    });
  };

  const handleExportDesign = (design: Design, extension: Extension) => {
    exportDesign({
      designId: design.id,
      partNumber: design.partNumber,
      partRevision: design.partRevision,
      extension: extension,
    });
  };

  const handleDeleteDesign = (design: Design) => {
    deleteDesign({ designId: design.id });
  };

  return (
    <Stack h="full" spacing={0}>
      <TopbarNav />
      <Stack flex={1} padding={8} spacing={8}>
        <Title title={'Designs'} aria-label="page-title">
          <Button onClick={openCreateModal} isLoading={createDesignPending}>
            New Design
          </Button>
        </Title>
        <DesignModal
          title="Create Design"
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
          onSubmit={handleCreateDesign}
          submitButtonText="Create"
        />
        <DesignModal
          title="Update Design"
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          onSubmit={handleUpdateDesign}
          submitButtonText="Save"
          initialData={selectedDesign}
        />
        <DesignModal
          title="Clone design"
          isOpen={isCloneModalOpen}
          onClose={closeCloneModal}
          onSubmit={handleCloneDesign}
          submitButtonText="Clone"
          initialData={selectedDesign}
        />
        <HStack gap={6}>
          <FilterBox placeholder="Type to filter" value={query} onChange={handleFilterQuery} />
          <Button
            onClick={() => {
              handleFilterQuery('');
            }}
          >
            Clear filters
          </Button>
        </HStack>
        <HStack alignItems="flex-start" spacing={0} height="full">
          <Flex width="full" height="100%" overflowY="auto">
            {isLoading ? (
              <Center height="50vh" width="80vw">
                <Loading message="Loading designs…" />
              </Center>
            ) : (
              <VStack width="full">
                <TableContainer width="full" overflowX="hidden">
                  <Table layout="fixed" variant="senraTable">
                    <colgroup>
                      <col style={{ width: spacing20 }} />
                      <col style={{ width: spacing80 }} />
                      <col style={{ minWidth: spacing80, width: 'auto' }} />
                      <col style={{ width: spacing40 }} />
                      <col style={{ width: spacing40 }} />
                      <col style={{ width: spacing40 }} />
                    </colgroup>
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Actions</Th>
                        <Th>Part Number</Th>
                        <Th>Description</Th>
                        <Th>Length Unit</Th>
                        <Th>Drawn By</Th>
                        <Th>Customer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {designs?.data.map((design) => (
                        <Tr
                          key={design.id}
                          role="group"
                          h="55px"
                          onClick={() => navigate(generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id }))}
                        >
                          <Td textAlign="center">
                            <DesignActions
                              onOpen={() => handleOpenDesign(design)}
                              onEdit={openEditModal}
                              onClone={openCloneModal}
                              onExportPdf={() => handleExportDesign(design, 'pdf')}
                              onExportExcel={() => handleExportDesign(design, 'xlsx')}
                              onDelete={() => handleDeleteDesign(design)}
                              onClick={(event: MouseEvent<HTMLDivElement>) => {
                                setSelectedDesign(design);
                                event.stopPropagation();
                              }}
                            />
                          </Td>
                          <Td>
                            <PartRevisionLabel partNumber={design.partNumber} partRevision={design.partRevision} />
                          </Td>
                          <Td>
                            {design.description && (
                              <Tooltip label={design.description}>
                                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                  {design.description}
                                </Text>
                              </Tooltip>
                            )}
                          </Td>
                          <Td>{design.lengthUnit}</Td>
                          <Td>
                            {design.creator?.name && (
                              <Tooltip label={design.creator.name}>
                                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                  {design.creator.name}
                                </Text>
                              </Tooltip>
                            )}
                          </Td>
                          <Td>
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" paddingRight={4}>
                              {design.tenant.name}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            )}
          </Flex>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default Designs;
