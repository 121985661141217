import { HStack, StackProps, Text } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { useUpdateLayoutEdgeMutation } from '@web/apps/Design/api/layout-edges-api.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import { XYPosition } from '@xyflow/react';

import EditableText from '../../../../../components/EditableText.tsx';
import { useDesignOverview } from '../../../../../hooks/useDesignOverview.tsx';
import { useDesignToast } from '../../../../../hooks/useDesignToast.tsx';
import { useLayoutBuilder } from '../../../hooks/useLayoutBuilder.tsx';

interface MeasurementEdgeLabelProps {
  edgeId: UUID;
  measurement: number;
  position: XYPosition;
}

/**
 * Measurement edge label component for displaying and editing the measurement value.
 * @param edgeId
 * @param measurement
 * @param position
 * @constructor
 */
const MeasurementEdgeLabel = ({ edgeId, measurement = 0, position }: MeasurementEdgeLabelProps) => {
  // Get the unit of measure
  const { id: designId, lengthUnit } = useDesignOverview();

  const { showErrorToast } = useDesignToast();

  // Get the graph builder to execute operations
  const { executeGraphOperation } = useLayoutBuilder();

  const { mutate: updateLayoutEdge } = useUpdateLayoutEdgeMutation();

  const { data: user } = useCurrentUser();

  // Define the label properties
  const labelProps: Partial<StackProps> = {
    h: '20px',
    w: '100px',
    fontFamily: 'mono',
    fontSize: 'sm',
    color: 'gray.900',
    position: 'absolute',
    top: position.y - 20,
    left: position.x,
    cursor: 'pointer',
  };

  // Save the measurement value
  const handleEdit = (value: string) => {
    // Parse the value to a number
    const newValue = parseFloat(value);
    if (isNaN(newValue) || newValue < 0) {
      showErrorToast('Error Updating Measurement', 'Please enter a valid number greater than or equal to zero');
      return;
    }

    if (user?.featureFlags.relationalLayoutData) {
      updateLayoutEdge({ designId, layoutEdgeId: edgeId, data: { measurement: Number(value) } });
    } else {
      // Update the edge data with the new measurement value
      executeGraphOperation({
        type: 'UpdateEdgeData',
        params: { edgeId, data: { measurement: Number(value) } },
      });
    }
  };

  return (
    <HStack {...labelProps} sx={{ pointerEvents: 'all' }}>
      <EditableText text={measurement.toString()} onEdit={handleEdit} bg="white" />
      <Text>{lengthUnit}</Text>
    </HStack>
  );
};

export default MeasurementEdgeLabel;
