import { DesignPart, UUID } from '@senrasystems/senra-ui';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { doRequest, getUrl } from '@web/common/api.ts';

// API Calls
const createDesignPart = async (designId: UUID, data: { name?: string; partId: UUID; designPartId?: UUID }) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts`);
  return await doRequest<DesignPart>('POST', url, JSON.stringify({ designPart: data }));
};

const renameDesignPart = async (designId: UUID, partId: UUID, data: { name: string }) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  return await doRequest<DesignPart>('PATCH', url, JSON.stringify({ designPart: data }));
};

const replaceDesignPart = async (designId: UUID, partId: UUID, newPartId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  return await doRequest<DesignPart>('PATCH', url, JSON.stringify({ designPart: { partId: newPartId } }));
};

const deleteDesignPart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}`);
  await doRequest('DELETE', url);
};

const updateDesignPart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${partId}/update_part_data`);
  return await doRequest<DesignPart>('PUT', url);
};

const createAlternatePart = async (designId: UUID, partId: UUID, alternatePartId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/alternate_design_parts`);
  return await doRequest<DesignPart>(
    'POST',
    url,
    JSON.stringify({
      alternateDesignPart: {
        partId,
        alternatePartId,
      },
    }),
  );
};

const deleteAlternatePart = async (designId: UUID, partId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/alternate_design_parts/${partId}`);
  await doRequest('DELETE', url);
};

// React Query Hook: useCreateDesignPartMutation
export interface CreateDesignPartParams {
  designId: UUID;
  data: { name?: string; partId: UUID; designPartId?: UUID };
}

export const useCreateDesignPartMutation = (
  onSuccess?: () => void,
): UseMutationResult<DesignPart | undefined, Error, CreateDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design-part'],
    mutationFn: async ({ designId, data }: CreateDesignPartParams) => createDesignPart(designId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      onSuccess?.();
    },
    onError: (error) => showErrorToast('Error Adding Part', error.message),
  });
};

// React Query Hook: useRenameDesignPartMutation
interface RenameDesignPartParams {
  designId: UUID;
  partId: UUID;
  data: { name: string };
}

export const useRenameDesignPartMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}): UseMutationResult<DesignPart | undefined, Error, RenameDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['rename-design-part'],
    mutationFn: async ({ designId, partId, data }: RenameDesignPartParams) => renameDesignPart(designId, partId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      onSuccess?.();
    },
    onError: (error) => {
      showErrorToast('Error Renaming Part', error.message);
      onError?.();
    },
  });
};

// React Query Hook: useReplaceDesignPartMutation
interface ReplaceDesignPartParams {
  designId: UUID;
  partId: UUID;
  newPartId: UUID;
}

export const useReplaceDesignPartMutation = (
  onSuccess?: () => void,
): UseMutationResult<DesignPart | undefined, Error, ReplaceDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['replace-design-part'],
    mutationFn: async ({ designId, partId, newPartId }: ReplaceDesignPartParams) =>
      replaceDesignPart(designId, partId, newPartId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Part Replaced', 'The part was successfully replaced.');
      onSuccess?.();
    },
    onError: (error) => showErrorToast('Error Replacing Part', error.message),
  });
};

// React Query Hook: useDeleteDesignPartMutation
interface DeleteDesignPartParams {
  designId: UUID;
  partId: UUID;
}

export const useDeleteDesignPartMutation = (): UseMutationResult<void, Error, DeleteDesignPartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design-part'],
    mutationFn: async ({ designId, partId }: DeleteDesignPartParams) => deleteDesignPart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Removing Part', error.message),
  });
};

// React Query Hook: useUpdateDesignPartMutation
interface UpdateDesignPartParams {
  designId: UUID;
  partId: UUID;
}

export const useUpdateDesignPartMutation = (): UseMutationResult<
  DesignPart | undefined,
  Error,
  UpdateDesignPartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-design-part'],
    mutationFn: async ({ designId, partId }: UpdateDesignPartParams) => updateDesignPart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Part updated successfully', 'The part has been updated in the design');
    },
    onError: (error) => showErrorToast('Error updating part', error.message),
  });
};

// React Query Hook: useCreateAlternatePartMutation
interface CreateAlternatePartParams {
  designId: UUID;
  partId: UUID;
  alternatePartId: UUID;
}

export const useCreateAlternatePartMutation = (): UseMutationResult<
  DesignPart | undefined,
  Error,
  CreateAlternatePartParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-alternate-part'],
    mutationFn: async ({ designId, partId, alternatePartId }: CreateAlternatePartParams) =>
      createAlternatePart(designId, partId, alternatePartId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Alternate Part Added', 'The part was successfully added as an alternate.');
    },
    onError: (error) => showErrorToast('Error Adding Alternate Part', error.message),
  });
};

// React Query Hook: useDeleteAlternatePartMutation
interface DeleteAlternatePartParams {
  designId: UUID;
  partId: UUID;
}

export const useDeleteAlternatePartMutation = (): UseMutationResult<void, Error, DeleteAlternatePartParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-alternate-part'],
    mutationFn: async ({ designId, partId }: DeleteAlternatePartParams) => deleteAlternatePart(designId, partId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
      showSuccessToast('Alternate Part Removed', 'The part was successfully removed as an alternate.');
    },
    onError: (error) => showErrorToast('Error Removing Alternate Part', error.message),
  });
};
