import { BaseEdge, Edge, EdgeLabelRenderer, EdgeProps } from '@xyflow/react';

import { defaultLayoutConfig } from '../../../config.ts';
import MeasurementEdgeLabel from './MeasurementEdgeLabel.tsx';
import { useMeasurementPaths } from './useMeasurementPaths.tsx';

export type MeasurementEdgeData = {
  flipped: boolean;
  distanceFromEdge: number;
  measurement: number;
};

export const defaultMeasurementEdgeData: MeasurementEdgeData = {
  flipped: false,
  distanceFromEdge: 30,
  measurement: 0,
};

const edgeStyle = { stroke: defaultLayoutConfig.measurementEdgeColor };

export type MeasurementEdgeType = Edge<MeasurementEdgeData>;

/**
 * Measurement-edge component representing a measurement line between two nodes.
 * @constructor
 * @param props
 */
const MeasurementEdge = (props: EdgeProps<MeasurementEdgeType>) => {
  const { id: edgeId, data = defaultMeasurementEdgeData, sourceX, sourceY, targetX, targetY } = props;
  const { flipped, distanceFromEdge, measurement } = data;

  const { startLinePath, measurementLinePath, endLinePath, midpoint } = useMeasurementPaths({
    sourcePosition: { x: sourceX, y: sourceY },
    targetPosition: { x: targetX, y: targetY },
    distanceFromEdge: distanceFromEdge,
    flipped: flipped,
  });

  return (
    <>
      <EdgeLabelRenderer>
        <MeasurementEdgeLabel edgeId={edgeId} position={midpoint} measurement={measurement} />
      </EdgeLabelRenderer>
      <BaseEdge path={startLinePath} style={edgeStyle} />
      <BaseEdge
        path={measurementLinePath}
        markerStart={'url(#measurement-arrow)'} // this is a reference to a marker in SVGDefinitions.tsx
        markerEnd={'url(#measurement-arrow)'} // this is a reference to a marker in SVGDefinitions.tsx
        style={edgeStyle}
      />
      <BaseEdge path={endLinePath} style={edgeStyle} />
    </>
  );
};

export default MeasurementEdge;
