export type Sortable = string | number;

/**
 * Compare two values for sorting, preferring numbers to strings.
 * @param a
 * @param b
 */
export const mixedSortCompare = (a: Sortable, b: Sortable): number => {
  const numA = typeof a === 'number' ? a : parseFloat(a);
  const numB = typeof b === 'number' ? b : parseFloat(b);

  const isNumA = !isNaN(numA);
  const isNumB = !isNaN(numB);

  if (isNumA && isNumB) {
    // Both are numbers, sort numerically
    return numA - numB;
  } else if (isNumA) {
    // Only 'a' is a number, it should come before b
    return -1;
  } else if (isNumB) {
    // Only b is a number, it should come before a
    return 1;
  } else {
    // Both are strings, sort alphabetically
    return (a as string).localeCompare(b as string);
  }
};

/**
 * Compare two values for sorting, preferring natural sorting.
 * @param a
 * @param b
 */
export const naturalCompare = (a: Sortable, b: Sortable) => {
  // Regex to split string into parts of numbers and non-numbers
  const re = /(\d+)|(\D+)/g;

  // Split strings into parts, providing a default empty array if match returns null
  const aParts = String(a).match(re) || [];
  const bParts = String(b).match(re) || [];

  // Compare each part of the string
  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    const aPart = aParts[i] || '';
    const bPart = bParts[i] || '';

    // Check if both parts are numbers
    const aNum = parseInt(aPart, 10);
    const bNum = parseInt(bPart, 10);

    // Numeric comparison if both parts are numbers
    if (!isNaN(aNum) && !isNaN(bNum)) {
      if (aNum !== bNum) {
        return aNum - bNum;
      }
    } else {
      // Lexicographic comparison for non-numeric parts
      if (aPart !== bPart) {
        return aPart.localeCompare(bPart);
      }
    }
  }

  return 0; // Equal strings
};
