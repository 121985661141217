import { Box, BoxProps, Center, HStack, Text } from '@chakra-ui/react';
import { centerAbsoluteVertical } from '@web/apps/styles.ts';
import { ConnectorAssembly } from '@web/apps/types/ConnectorAssembly.ts';
import { Position } from '@xyflow/react';

import { defaultSchematicConfig } from '../../../config.ts';
import { SchematicPoint } from '../../../types/SchematicConnectionGroup.ts';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  point: SchematicPoint | null;
  isSource: boolean;
}

/**
 * Cavity component. This component is used to represent a cavity in the schematic. It has a connection handle on the
 * right side of the cavity if it is a source cavity, and on the left side if it is a destination cavity.
 * @param point
 * @param isSource
 * @param rest
 * @constructor
 */
export const Cavity = ({ point, isSource = true, ...rest }: Props) => {
  const isHousing = point?.name === ConnectorAssembly.HOUSING;
  const name = isHousing ? 'BS' : point?.name;
  const handlePosition = isSource ? Position.Right : Position.Left;

  return (
    <Center
      position="relative"
      h={defaultSchematicConfig.cavityHeight}
      w={defaultSchematicConfig.cavityWidth}
      bg={defaultSchematicConfig.cavityColor}
      borderRadius="md"
      {...rest}
    >
      <Text fontFamily="monospace" fontSize="sm">
        {name}
      </Text>
      <Box left={isSource ? '100%' : 'auto'} right={isSource ? 'auto' : '100%'} sx={centerAbsoluteVertical}>
        <HStack spacing={0}>
          {!isHousing && (
            <Box w={10} h={defaultSchematicConfig.conductorStrokeWidth} bg={defaultSchematicConfig.conductorColor} />
          )}
          {point && <ConnectionHandle id={point.handleId} type="source" position={handlePosition} />}
        </HStack>
      </Box>
    </Center>
  );
};
