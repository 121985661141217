import {
  Box,
  BoxProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { DesignPart, isAccessory, isLinear, PartType } from '@senrasystems/senra-ui';
import { MouseEvent, useState } from 'react';
import { BiSolidCircle } from 'react-icons/bi';
import { SlOptionsVertical } from 'react-icons/sl';

import { useDeleteDesignPartMutation, useRenameDesignPartMutation } from '../../../../api/design-parts-api.ts';
import EditableText from '../../../../components/EditableText.tsx';
import { SELECTION_TYPES, useDesign } from '../../../../hooks/useDesign.tsx';
import DiffIndicator from '../DiffIndicator.tsx';

interface Props extends BoxProps {
  part: DesignPart;
  onReplacePart?: (part: DesignPart) => void;
  designLengthUnit?: string;
  hasFocus?: boolean;
  hideDiffIndicator?: boolean;
}

/**
 * DesignPartListItem component displays a single part in a list of parts.
 * @param part
 * @param designParts
 * @param rest
 * @constructor
 */
const PartListItem = ({ part, onReplacePart, designLengthUnit, hasFocus, hideDiffIndicator, ...rest }: Props) => {
  const { designId, isViewOnly, isSelected, selections, setSelection } = useDesign();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [currentPartName, setCurrentPartName] = useState(part.name);
  const selected = isSelected('designPart', part.id);

  // Mutation to rename a part
  const { mutate: renameDesignPart } = useRenameDesignPartMutation({ onError: () => setCurrentPartName(part.name) });

  // Mutation to delete a part
  const { mutate: deleteDesignPart } = useDeleteDesignPartMutation();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      event.stopPropagation();
      setSelection(SELECTION_TYPES.DESIGN_PART, part.id);
    }
  };

  const handleMouseEnter = () => {
    if (!isMenuOpen) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMenuOpen) {
      setIsHovered(false);
    }
  };

  const handleDeletePart = () => {
    !isViewOnly && deleteDesignPart({ designId, partId: part.id });
  };

  const handleRenamePart = (nextName: string) => {
    setCurrentPartName(nextName);
    !isViewOnly && renameDesignPart({ designId, partId: part.id, data: { name: nextName } });
  };

  const handleReplacePart = () => {
    if (!isViewOnly && onReplacePart) {
      onReplacePart(part);
    }
  };

  return (
    <Box
      cursor="pointer"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      bg={selected ? 'blue.100' : 'transparent'}
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor={isHovered && part.partData.type !== PartType.CONNECTOR ? 'blue.300' : 'transparent'}
      {...rest}
    >
      <HStack h={8} w="full">
        <Box
          as={BiSolidCircle}
          fontSize=".5em"
          color="gray.400"
          opacity={part.partData.partNumber === selections.bomItem ? 1 : 0}
          transition="opacity .3s ease-in-out"
        />
        {!hideDiffIndicator && <DiffIndicator part={part} designId={designId} />}
        {!isAccessory(part.partData.type) && (
          <EditableText
            text={currentPartName}
            onEdit={handleRenamePart}
            size="sm"
            isTruncated={true}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            width="150px"
          />
        )}
        <HStack onDoubleClick={handleReplacePart}>
          <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="blue.500">
            {part.partData.partNumber}
          </Text>
          {part?.includedAccessory && <Text color="gray.600">Included</Text>}
        </HStack>
        {isLinear(part.partData.type) && (
          <Text>
            ({part.quantity} {designLengthUnit || ''})
          </Text>
        )}
        <Spacer />
        {!isViewOnly && (
          <Menu isOpen={isMenuOpen} onOpen={onMenuOpen} onClose={onMenuClose}>
            <MenuButton
              as={IconButton}
              size="sm"
              icon={<SlOptionsVertical />}
              _hover={{ bg: 'blue.100' }}
              bg="transparent"
              opacity={isHovered || (selected && hasFocus) ? 1 : 0}
              transition="opacity .3s ease-in-out"
              onClick={(event) => event.stopPropagation()}
            />
            <MenuList>
              <Tooltip label={part?.includedAccessory ? 'This part is included with the parent component' : ''}>
                <MenuItem isDisabled={part?.includedAccessory} onClick={handleDeletePart}>
                  Remove
                </MenuItem>
              </Tooltip>
              <Tooltip label={part?.includedAccessory ? 'This part is included with the parent component' : ''}>
                <MenuItem isDisabled={part?.includedAccessory} onClick={handleReplacePart}>
                  Replace
                </MenuItem>
              </Tooltip>
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Box>
  );
};

export default PartListItem;
