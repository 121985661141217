import { Collapse } from '@chakra-ui/icons';
import { Flex, HStack } from '@chakra-ui/layout';
import { Box, IconButton } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { VscChromeMaximize, VscChromeMinimize, VscSplitVertical } from 'react-icons/vsc';

import { PanelViewHeight } from './types.ts';
import { isOpen } from './utils.ts';

interface Props {
  height: PanelViewHeight;
  setHeight: (height: PanelViewHeight) => void;
  headerContent: ReactNode;
  children: ReactNode;
}

const buttonConfigs = [
  { panelHeight: PanelViewHeight.MINIMIZED, icon: <VscChromeMinimize />, label: 'Minimize Context Panel' },
  { panelHeight: PanelViewHeight.SPLIT, icon: <VscSplitVertical />, label: 'Open Context Panel' },
  { panelHeight: PanelViewHeight.FULL, icon: <VscChromeMaximize />, label: 'Maximize Context Panel' },
];

const ContextPanel = ({ height, setHeight, headerContent, children }: Props) => {
  const handleDoubleClick = () => {
    isOpen(height) ? setHeight(PanelViewHeight.MINIMIZED) : setHeight(PanelViewHeight.SPLIT);
  };

  return (
    <Flex flexDirection="column">
      <HStack
        p={3}
        width="full"
        color="gray.500"
        bg="gray.100"
        borderTop={height === PanelViewHeight.FULL ? 'none' : '1px solid'}
        borderBottom="1px solid"
        borderColor="gray.300"
        onDoubleClick={handleDoubleClick}
        justify="space-between"
        fontWeight="500"
        userSelect="none"
        textTransform="uppercase"
      >
        <Box flex={1} />
        {headerContent}
        <HStack flex={1} justify="flex-end">
          {buttonConfigs.map(
            ({ panelHeight, icon, label }) =>
              height !== panelHeight && (
                <IconButton key={panelHeight} onClick={() => setHeight(panelHeight)} icon={icon} aria-label={label} />
              ),
          )}
        </HStack>
      </HStack>
      <Collapse in={isOpen(height)} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  );
};

export default ContextPanel;
