import { Box, BoxProps, Grid, HStack, Text } from '@chakra-ui/react';
import { conductorSymbolOffset } from '@web/apps/Design/features/Schematic/types/positioning.ts';
import { centerAbsoluteVertical } from '@web/apps/styles.ts';
import { Position } from '@xyflow/system';

import { ColorBlock } from '../../../../../components/ColorBlock.tsx';
import { defaultSchematicConfig } from '../../../config.ts';
import { SchematicConductor } from '../../../types/SchematicConnectionGroup.ts';
import { ConductorDetail } from './ConductorDetail.tsx';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  conductor: SchematicConductor;
}

/**
 * Conductor component. Represents a conductor in the schematic with connection handles on the left and right sides.
 */
export const Conductor = ({ conductor, ...rest }: Props) => {
  const { isShield, isVisible, partNumber, gauge, signalName, displayName, leftHandleId, rightHandleId } = conductor;

  return (
    <Box position="relative" h={defaultSchematicConfig.cavityHeight} w="full" {...rest}>
      {/* Conductor line */}
      {isVisible && (
        <Box
          h={defaultSchematicConfig.conductorStrokeWidth}
          w="full"
          bg={defaultSchematicConfig.conductorColor}
          sx={centerAbsoluteVertical}
        />
      )}

      {/* Conductor details */}
      {isVisible && (
        <Box w="full" sx={centerAbsoluteVertical}>
          <Grid templateColumns="1fr 2fr repeat(3, 1fr) 2fr 1fr" gap={2}>
            <ConductorDetail />
            <ConductorDetail>{partNumber}</ConductorDetail>
            <ConductorDetail color="gray.500">{gauge ? `${gauge} AWG` : null}</ConductorDetail>
            <ConductorDetail />
            <ConductorDetail color="gray.500">{signalName}</ConductorDetail>
            <ConductorDetail>
              <HStack bg={defaultSchematicConfig.style.backgroundColor} px={2}>
                <ColorBlock text={displayName} />
                <Text color="gray.900">{displayName}</Text>
              </HStack>
            </ConductorDetail>
            <ConductorDetail />
          </Grid>
        </Box>
      )}

      {/* Left Connection Handle */}
      <Box sx={isShield ? { position: 'absolute', top: '-40px', left: `${conductorSymbolOffset}px` } : {}}>
        <ConnectionHandle id={leftHandleId} type="source" position={Position.Left} />
      </Box>

      {/* Right Connection Handle */}
      <Box sx={isShield ? { position: 'absolute', top: '-40px', right: `${conductorSymbolOffset}px` } : {}}>
        <ConnectionHandle id={rightHandleId} type="source" position={Position.Right} />
      </Box>
    </Box>
  );
};
