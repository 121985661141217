import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { OptionBase, Select } from 'chakra-react-select';

import SearchableDropdownOption from './SearchableDropdownOption.tsx';

export interface Option extends OptionBase {
  value: string;
  label: string;
}

export interface GroupedOption {
  label: string;
  options: Option[];
}

interface Props {
  label?: string;
  placeholder?: string;
  options: Option[] | GroupedOption[];
  value: Option | null;
  onChange: (option: Option | null) => void;
}

const SearchableDropdown = ({ label, placeholder, options, onChange, value }: Props) => {
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <Box position="relative">
        <Select<Option>
          components={{ Option: SearchableDropdownOption }}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          isClearable
          size="sm"
          formatGroupLabel={(data) => <Text textTransform="uppercase">{data.label}</Text>}
          menuPlacement="auto"
          menuPosition="fixed"
          menuPortalTarget={document.body}
        />
      </Box>
    </FormControl>
  );
};

export default SearchableDropdown;
