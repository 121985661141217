import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { useUpdateLayoutEdgeMutation } from '@web/apps/Design/api/layout-edges-api.ts';
import { useDesignOverview } from '@web/apps/Design/hooks/useDesignOverview.tsx';
import { useCurrentUser } from '@web/queries/users.ts';

import {
  defaultMeasurementEdgeData,
  MeasurementEdgeType,
} from '../../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { GraphOperation } from '../../graph/Operations.ts';
import { EdgeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for measurement actions.
 */
export const useMeasurementActions = (elementType: EdgeType) => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const edge = element as MeasurementEdgeType;
  const { id: designId } = useDesignOverview();
  const { data: user } = useCurrentUser();
  const { mutate: updateLayoutEdge } = useUpdateLayoutEdgeMutation();

  // Data properties
  const flipped = edge?.data?.flipped ?? false;
  const distanceFromEdge =
    edge?.data?.distanceFromEdge.toString() ?? defaultMeasurementEdgeData.distanceFromEdge.toString();

  const onFlip = () => {
    if (user?.featureFlags.relationalLayoutData) {
      updateLayoutEdge({ designId, layoutEdgeId: edge.id, data: { flipped: !flipped } });
    } else {
      const params: GraphOperation = {
        type: 'UpdateEdgeData',
        params: { edgeId: edge.id, data: { flipped: !flipped } },
      };
      executeOperation(params);
    }
    closeMenu();
  };

  const onExtend = (distance: number) => {
    if (user?.featureFlags.relationalLayoutData) {
      updateLayoutEdge({ designId, layoutEdgeId: edge.id, data: { distanceFromEdge: distance } });
    } else {
      const params: GraphOperation = {
        type: 'UpdateEdgeData',
        params: { edgeId: edge.id, data: { distanceFromEdge: distance } },
      };
      executeOperation(params);
    }
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onFlip}>Flip</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuOptionGroup defaultValue={distanceFromEdge} title="Step out:" type="radio">
        <MenuItemOption onClick={() => onExtend(30)} value="30">
          1x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(60)} value="60">
          2x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(90)} value="90">
          3x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(150)} value="150">
          5x
        </MenuItemOption>
        <MenuItemOption onClick={() => onExtend(240)} value="240">
          8x
        </MenuItemOption>
      </MenuOptionGroup>
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType,
  } as MenuActions;
};
