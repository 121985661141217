import { UUID } from '@senrasystems/senra-ui';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { downloadFile, getBaseOptions, getUrl } from '@web/common/api.ts';

// API Calls
const getCutlistURL = (designId: UUID, extension: 'xlsx' | 'json') =>
  getUrl(`/api/v1/designs/${designId}/cutlist.${extension}`);

const fetchCutlistExcel = async (designId: UUID) => {
  const url = getCutlistURL(designId, 'xlsx');

  const options = await getBaseOptions('GET');
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error('Failed to download cutlist');
  }

  return response.blob();
};

// React Query Hook: useDownloadCutlistExcel
export const useDownloadCutlistExcel = (designId: UUID, partNumber: string, partRevision: string) => {
  const { showErrorToast, showSuccessToast } = useDesignToast();

  return useMutation({
    mutationKey: ['download-cutlist'],
    mutationFn: async () => {
      const filename = `cutlist-${partNumber}${partRevision ? `_${partRevision}` : ''}.xlsx`;
      const blob = await fetchCutlistExcel(designId);
      downloadFile(blob, filename);
    },
    onSuccess: () => {
      showSuccessToast('Download Complete', 'Cutlist file downloaded');
    },
    onError: (error) => {
      showErrorToast('Download Failed', error.message);
    },
  });
};

// React Query Hook: useCutlistData
export interface CutlistItemData {
  id: string;
  partNumber: string;
  length: string;
}

export const useCutlistData = (designId: UUID): UseQueryResult<CutlistItemData[]> => {
  return useQuery({
    queryKey: designKeys.cutlist(designId),
    queryFn: () => doRequest('GET', getCutlistURL(designId, 'json')),
  });
};
