import { LayoutNode, UUID } from '@senrasystems/senra-ui';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { doRequest, getUrl } from '@web/common/api.ts';

// API Calls
const updateLayoutNode = async (designId: UUID, layoutNodeId: UUID, data: Partial<LayoutNode>) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes/${layoutNodeId}`);
  return await doRequest<LayoutNode>('PUT', url, JSON.stringify({ layoutNode: data }));
};

// React Query Hook: useUpdateLayoutNodeMutation
interface UpdateLayoutNodeParams {
  designId: UUID;
  layoutNodeId: UUID;
  data: Partial<LayoutNode>;
}

export const useUpdateLayoutNodeMutation = (): UseMutationResult<
  LayoutNode | undefined,
  Error,
  UpdateLayoutNodeParams
> => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationKey: ['update-layout-node'],
    mutationFn: async ({ designId, layoutNodeId, data }: UpdateLayoutNodeParams) =>
      updateLayoutNode(designId, layoutNodeId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => console.error('Error updating layout node', error.message),
  });
};
