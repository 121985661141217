import { chakraComponents, GroupBase, OptionProps } from 'chakra-react-select';

// Define the structure for custom option data
export interface CustomOptionType {
  label: string;
  value: string;
  colorScheme?: string;
}

// Custom Option Component
const SearchableDropdownOption = <
  Option extends CustomOptionType,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => {
  const { data, innerRef, innerProps, children, isSelected, isFocused } = props;

  return (
    <chakraComponents.Option
      {...props}
      innerRef={innerRef}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps.style,
          backgroundColor: isSelected
            ? `var(--chakra-colors-${data.colorScheme || 'gray'}-600)`
            : isFocused
              ? `var(--chakra-colors-${data.colorScheme || 'gray'}-100)`
              : undefined,
        },
      }}
    >
      {children}
    </chakraComponents.Option>
  );
};

export default SearchableDropdownOption;
