import { AlternateDesignPart, BOMItem, BOMItemUsage, generateBOM, UUID } from '@senrasystems/senra-ui';
import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { useMemo } from 'react';

export type bomCSVItem = {
  ID: number;
  Type: string;
  Qty: number;
  Unit: string;
  'Part Number': string;
  Usage: string;
  Description: string;
  Manufacturer: string;
  Alts: string;
};

/**
 * Hook to generate a Bill of Materials (BOM) from a design.
 * @param designId
 */
export const useBOM = (designId: UUID) => {
  const { data: design, isLoading, isSuccess, error } = useDesignQuery(designId);

  // Memoize the BOM generation based on changes to the design
  const bom = useMemo(() => {
    if (!design) return [] as BOMItem[];
    return generateBOM([design]);
  }, [design]);

  // Memoize the BOM CSV generation based on changes to the BOM
  const bomCSV = useMemo(() => {
    return bom.map((item: BOMItem) => ({
      ID: item.itemNumber,
      Type: item.displayType,
      Qty: item.quantity,
      Unit: item.unit,
      'Part Number': item.partNumber,
      Usage: item.usages.map((usage: BOMItemUsage) => usage.name).join(', '),
      Description: item.description,
      Manufacturer: item.manufacturer,
      Alts: item.alternates.map((alternate: AlternateDesignPart) => alternate.alternatePart.partNumber).join(', '),
    }));
  }, [bom]);

  return { bom, bomCSV, isLoading, isSuccess, error };
};
