import { QueryKey } from '@tanstack/query-core';
import { useQueryClient } from '@tanstack/react-query';
import { useWindowManager } from '@web/apps/Design/hooks/useWindowManager.tsx';

/**
 * Invalidate queries and post a message to other windows to invalidate their queries.
 */
export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();
  const { postInvalidateQueriesMessage } = useWindowManager();
  return async (queryKey: QueryKey) => {
    await queryClient.invalidateQueries({ queryKey });
    postInvalidateQueriesMessage(queryKey);
  };
};
