import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { BOMItem } from '@senrasystems/senra-ui';
import Papa from 'papaparse';
import { BiCopyAlt } from 'react-icons/bi';

import { bomCSVItem } from '../../../hooks/useBOM';
import { useDesignToast } from '../../../hooks/useDesignToast';

interface Props {
  bom: BOMItem[];
  bomCSV: bomCSVItem[];
}

export const CopyBOMButton = ({ bom, bomCSV }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showSuccessToast, showErrorToast } = useDesignToast();

  const copyFullBom = async () => {
    try {
      const csv = Papa.unparse(bomCSV, { delimiter: '\t' });
      await navigator.clipboard.writeText(csv);
      showSuccessToast('Success', 'BOM copied to clipboard.');
    } catch {
      showErrorToast('Error', 'Failed to copy BOM to clipboard.');
    }

    onClose();
  };

  const copyBomForQuotingTool = async () => {
    const bomItemsForQuotingTool = bom.map(({ partNumber, manufacturer, description, quantity, unit }) => [
      partNumber,
      manufacturer,
      description,
      `${quantity}${unit}`,
    ]);

    try {
      const bomString = Papa.unparse(bomItemsForQuotingTool, { delimiter: '\t', newline: '\n' });
      await navigator.clipboard.writeText(bomString);
      showSuccessToast('Success', 'BOM copied to clipboard.');
    } catch {
      showErrorToast('Error', 'Failed to copy BOM to clipboard.');
    }

    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton aria-label="open copy options" variant="unstyled" icon={<BiCopyAlt fontSize="1.5em" />}>
          Trigger
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Stack>
            <Button onClick={copyFullBom}>Copy BOM</Button>
            <Button onClick={copyBomForQuotingTool}>Copy Quoting BOM</Button>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
