import {
  AlternateDesignPart,
  Design,
  DesignPart,
  isConnectorDesignPart,
  isOverwrapDesignPart,
  UUID,
} from '@senrasystems/senra-ui';
import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { useMemo } from 'react';

import { useDesignId } from './useDesignId.tsx';

// Define the return type
interface UseDesignPartsResult {
  designParts: DesignPart[];
  alternateDesignParts: AlternateDesignPart[];
  designPartsMap: Map<UUID, DesignPart>;
  alternateDesignPartsMap: Map<UUID, AlternateDesignPart>;
  partNumberOverwrapMap: Map<string, DesignPart>;
  getDesignPartById: (id: UUID | null) => DesignPart | undefined;
  getAlternateDesignPartById: (id: UUID | null) => AlternateDesignPart | undefined;
  getOverwrapByPartNumber: (partNumber: string) => DesignPart | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
}

// Initialize designParts and alternateDesignParts
const emptyDesignParts = [] as DesignPart[];
const emptyAlternateDesignParts = [] as AlternateDesignPart[];

// Define the select function
const selectDesignParts = (design: Design | undefined) => ({
  designParts: design?.designParts || emptyDesignParts,
  alternateDesignParts: design?.alternateDesignParts || emptyAlternateDesignParts,
});

/**
 * Custom hook to fetch designParts, alternateDesignParts, lookup maps, and utility functions. This hook uses the
 * select function of useDesignQuery to only subscribe to designParts and alternateDesignParts.
 */
export const useDesignParts = (): UseDesignPartsResult => {
  const designId = useDesignId();

  // Fetch designParts and alternateDesignParts
  const { data, isLoading, isSuccess, error } = useDesignQuery(designId, selectDesignParts);
  const designParts = data?.designParts || emptyDesignParts;
  const alternateDesignParts = data?.alternateDesignParts || emptyAlternateDesignParts;

  // Create a map of designParts for easy lookup
  const designPartsMap = useMemo(() => {
    const map = new Map<UUID, DesignPart>();
    designParts.forEach((part) => {
      map.set(part.id, part);
      if (isConnectorDesignPart(part)) {
        if (part.backshell) {
          map.set(part.backshell.id, part.backshell);
        }
        part.contacts?.forEach((contact) => {
          map.set(contact.id, contact);
        });
      }
    });
    return map;
  }, [designParts]);

  // Create a map of alternateDesignParts for easy lookup
  const alternateDesignPartsMap = useMemo(() => {
    const map = new Map<UUID, AlternateDesignPart>();
    alternateDesignParts.forEach((alternatePart) => {
      map.set(alternatePart.id, alternatePart);
    });
    return map;
  }, [alternateDesignParts]);

  // Create a map of overwrap design parts for easy lookup
  const partNumberOverwrapMap = useMemo(() => {
    const overwrapMap = new Map<string, DesignPart>();
    designParts.forEach((part) => {
      if (isOverwrapDesignPart(part)) {
        overwrapMap.set(part.partData.partNumber, part);
      }
    });
    return overwrapMap;
  }, [designParts]);

  // Utility function to get a design part by ID
  const getDesignPartById = (id: UUID | null): DesignPart | undefined => {
    return id ? designPartsMap.get(id) : undefined;
  };

  // Utility function to get an alternate design part by ID
  const getAlternateDesignPartById = (id: UUID | null): AlternateDesignPart | undefined => {
    return id ? alternateDesignPartsMap.get(id) : undefined;
  };

  // Utility function to get an overwrap by partNumber
  const getOverwrapByPartNumber = (partNumber: string | null): DesignPart | undefined => {
    return partNumber ? partNumberOverwrapMap.get(partNumber) : undefined;
  };

  return {
    designParts,
    alternateDesignParts,
    designPartsMap,
    alternateDesignPartsMap,
    partNumberOverwrapMap,
    getDesignPartById,
    getAlternateDesignPartById,
    getOverwrapByPartNumber,
    isLoading,
    isSuccess,
    error,
  };
};
