import { UUID } from '@senrasystems/senra-ui';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { getUrl } from '@web/common/api.ts';
import { Edge, Node } from '@xyflow/react';

// Utility Constants and Functions
interface SchematicResponse {
  nodes: Node[];
  edges: Edge[];
}

const emptySchematicResponse: SchematicResponse = {
  nodes: [],
  edges: [],
};

const fetchSchematic = async (designId: UUID, designPartId: UUID): Promise<SchematicResponse> => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${designPartId}/schematic`);
  return (await doRequest<SchematicResponse>('GET', url)) || emptySchematicResponse;
};

// React Query Hook: useSchematicQuery
export const useSchematicQuery = <TData = SchematicResponse>(
  designId: UUID,
  designPartId: UUID,
  select?: (data: SchematicResponse) => TData,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: designKeys.schematic(designId, designPartId),
    queryFn: () => fetchSchematic(designId, designPartId),
    select,
    enabled: !!designPartId,
  });
};
