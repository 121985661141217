import { PageData } from '@senrasystems/senra-ui';
import { BAD_REQUEST, getBaseOptions } from '@web/common/api.ts';
import camelize from 'camelize';

// Type Definitions
type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface ErrorResponse {
  message?: string;
}

type EmptyResponse<T> = {
  data: T[];
  meta: PageData;
};

// RequestError Class
class RequestError extends Error {
  constructor(
    message: string,
    public status: number,
  ) {
    super(message);
    this.name = 'RequestError';
  }
}

// Utility Functions
export async function doRequest<T>(method: RequestMethod, url: string, body?: unknown): Promise<T | undefined> {
  const options = await getBaseOptions(method, body instanceof FormData ? null : 'application/json');
  if (body) {
    options.body = body as BodyInit;
  }

  const res = await fetch(url, options);

  let json: T | undefined;

  try {
    const text = await res.text();
    json = text ? camelize(JSON.parse(text)) : {};
  } catch (e) {
    if (e instanceof SyntaxError && res.status === 204) {
      // No content response (204) - empty response body is expected
    } else {
      throw new RequestError('Failed to parse response JSON', res.status);
    }
  }

  if (res.ok) {
    return json;
  } else {
    const errorJSON = json as ErrorResponse;
    if (res.status === 400) {
      const message = errorJSON.message || 'Validation Error';

      throw new RequestError(`${BAD_REQUEST} - ${message}`, res.status);
    }

    if ([403, 404].includes(res.status)) {
      window.location.assign('/404');
      throw new RequestError('Redirect to 404 page', res.status);
    }

    throw new RequestError(errorJSON.message || 'Unknown error', res.status);
  }
}

export const createEmptyResponse = <T>(): EmptyResponse<T> => ({
  data: [],
  meta: { currentPage: 0, totalPages: 0, totalCount: 0 },
});
