import { Box, BoxProps, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import Confirm from '@web/components/Confirm.tsx';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props extends BoxProps {
  onOpen: () => void;
  onEdit: () => void;
  onClone: () => void;
  onExportPdf: () => void;
  onExportExcel: () => void;
  onDelete: () => void;
}

export const DesignActions = ({ onOpen, onEdit, onClone, onExportPdf, onExportExcel, onDelete, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={IconButton} icon={<SlOptionsVertical />} aria-label="Design Actions" />
        <Portal>
          <MenuList>
            <MenuItem onClick={onOpen}>Open Design</MenuItem>
            <MenuItem onClick={onEdit}>Edit Details</MenuItem>
            <MenuItem onClick={onClone}>Clone</MenuItem>
            <MenuItem onClick={onExportPdf}>Export to PDF</MenuItem>
            <MenuItem onClick={onExportExcel}>Export to Excel</MenuItem>
            <Confirm
              title="Are you sure?"
              body=" This will delete the design. This action cannot be undone."
              onConfirm={onDelete}
            >
              <MenuItem color="red.300">Delete</MenuItem>
            </Confirm>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};
