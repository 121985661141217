import { Box, HStack, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends StackProps {
  isHeader?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
}

export const ItemRow = ({ isHeader, isSelected, children, ...rest }: Props) => {
  const styles = {
    color: isHeader ? 'gray.600' : 'gray.500',
    fontWeight: isHeader ? '500' : 'normal',
    borderColor: 'gray.200',
  };

  return (
    <HStack w="full" p={1} gap={4} {...styles} {...rest}>
      <Box w="5px" h="24px" bg={isSelected ? 'blue.200' : 'transparent'} />
      {children}
    </HStack>
  );
};
