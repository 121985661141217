import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import ContextPanel from './ContextPanel';
import { PanelViewHeight } from './types.ts';
import { isOpen } from './utils.ts';

interface TabData {
  value: string;
  headerLabel: string;
  content: ReactNode;
}

interface Props {
  tabs: TabData[];
}

const ContextPanelWithTabs = ({ tabs }: Props) => {
  const [height, setHeight] = useState<PanelViewHeight>(PanelViewHeight.MINIMIZED);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);

  const handleOnTabClick = (tabValue: string) => {
    if (isOpen(height) && selectedTab === tabValue) {
      setHeight(PanelViewHeight.MINIMIZED);
    } else {
      if (!isOpen(height)) {
        setHeight(PanelViewHeight.SPLIT);
      }
    }
    setSelectedTab(tabValue);
  };

  return (
    <Tabs variant="unstyled" bg="white">
      <ContextPanel
        height={height}
        setHeight={setHeight}
        headerContent={
          <TabList gap={6}>
            {tabs.map(({ value, headerLabel }) => (
              <Tab
                key={value}
                onClick={() => handleOnTabClick(value)}
                fontWeight="600"
                color="gray.400"
                _selected={{ color: tabs.length === 1 ? 'gray.500' : 'gray.900' }}
              >
                {headerLabel}
              </Tab>
            ))}
          </TabList>
        }
      >
        <TabPanels h={height} overflow="auto">
          {tabs.map(({ value, content }) => (
            <TabPanel p={0} key={value}>
              {content}
            </TabPanel>
          ))}
        </TabPanels>
      </ContextPanel>
    </Tabs>
  );
};

export default ContextPanelWithTabs;
