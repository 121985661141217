import { ReactNode } from 'react';

import { CutlistItemData } from '../../api/cutlist-api.ts';
import { ItemCell } from '../../components/ItemTable/ItemCell';
import { ItemRow } from '../../components/ItemTable/ItemRow';

interface Props {
  isHeader?: boolean;
  cutlistData?: CutlistItemData;
}

export const CutlistItem = ({ isHeader, cutlistData }: Props) => {
  const getCellValue = (headerValue: ReactNode, cellValue: ReactNode) => (isHeader ? headerValue : cellValue);

  return (
    <ItemRow isHeader={isHeader}>
      <ItemCell flex={1}>{getCellValue('ID', cutlistData?.id)}</ItemCell>
      <ItemCell flex={2}>{getCellValue('Part Number', cutlistData?.partNumber)}</ItemCell>
      <ItemCell flex={2}>{getCellValue('Length', cutlistData?.length)}</ItemCell>
    </ItemRow>
  );
};
