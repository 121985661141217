import {
  ConnectionMap,
  ConnectionPointMap,
  resolveConnection,
  resolveConnectionPoints,
  ResolvedConnection,
  ResolvedConnectionPoint,
} from '@senrasystems/senra-ui';
import { UseMutationResult } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import {
  CreateConnectionParams,
  DeleteConnectionParams,
  UpdateConnectionParams,
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
  useUpdateConnectionMutation,
} from '../api/connections-api.ts';
import { emptyConnections, emptyDesignParts, useConnectionsData } from './useConnectionsData.tsx';

// Interface for useConnections hook.
interface UseConnections {
  // Connection points
  connectionPoints: ResolvedConnectionPoint[];
  connectionPointsById: ConnectionPointMap;
  // Connections
  connections: ResolvedConnection[];
  bidirectionalConnections: ResolvedConnection[];
  connectionsById: ConnectionMap;
  connectionsByConductorId: ConnectionMap;
  connectionsBySourceId: ConnectionMap;
  // Connection CRUD
  getConnectionById: (id: string) => ResolvedConnection | undefined;
  createConnection: UseMutationResult<unknown, Error, CreateConnectionParams, unknown>;
  updateConnection: UseMutationResult<unknown, Error, UpdateConnectionParams, unknown>;
  deleteConnection: UseMutationResult<unknown, Error, DeleteConnectionParams, unknown>;
  // Design query status
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
}

/**
 * UseConnections hook provides access to connections, connection points, and CRUD operations for connections.
 */
export const useConnections = (): UseConnections => {
  // Fetch connections and design parts (for connection points)
  const { data, isLoading, isSuccess, error } = useConnectionsData();
  const connections = data?.connections || emptyConnections;
  const designParts = data?.designParts || emptyDesignParts;

  // Define crud operations
  const createConnection = useCreateConnectionMutation();
  const updateConnection = useUpdateConnectionMutation();
  const deleteConnection = useDeleteConnectionMutation();

  /**
   * Create a list of connection points from the design object.
   */
  const connectionPoints = useMemo(() => {
    return resolveConnectionPoints(designParts || []);
  }, [designParts]);

  /**
   * Create a map of connection points by id for easy lookup.
   */
  const connectionPointsById = useMemo((): ConnectionPointMap => {
    return keyBy(connectionPoints, 'id');
  }, [connectionPoints]);

  /**
   * Resolved each connection to include the objects that each connection point is mapped to.
   */
  const resolvedConnections = useMemo(() => {
    const result: ResolvedConnection[] = [];
    connections.forEach((connection) => {
      result.push(resolveConnection(connection, connectionPointsById));
    });
    return result;
  }, [connectionPointsById, connections]);

  /**
   * Connections are stored in the design object as one connection with a sourceId and destinationId. In some use cases,
   * we need a list of connections that includes both the original connection and the reverse connection so that there
   * is a consistent way to know if a connection exists from the perspective of the selected connector.
   */
  const bidirectionalConnections = useMemo(() => {
    const result: ResolvedConnection[] = [];
    connections.forEach((connection) => {
      result.push(resolveConnection(connection, connectionPointsById));
      result.push(
        resolveConnection(
          {
            ...connection,
            sourceId: connection.destinationId,
            destinationId: connection.sourceId,
            sourceContactId: connection.destinationContactId,
            destinationContactId: connection.sourceContactId,
          },
          connectionPointsById,
        ),
      );
    });
    return result;
  }, [connectionPointsById, connections]);

  /**
   * Create a map of connections by id for easy lookup.
   */
  const connectionsById = useMemo((): ConnectionMap => {
    return keyBy(resolvedConnections, 'id');
  }, [resolvedConnections]);

  /**
   * Create a map of connections by conductorId for easy lookup.
   */
  const connectionsByConductorId = useMemo((): ConnectionMap => {
    const map: ConnectionMap = {};
    bidirectionalConnections.forEach((c) => {
      if (c.conductor) {
        map[c.conductor.id] = c;
      }
    });
    return map;
  }, [bidirectionalConnections]);

  /**
   * Create a map of connections by sourceId for easy lookup.
   */
  const connectionsBySourceId = useMemo((): ConnectionMap => {
    const map: ConnectionMap = {};
    bidirectionalConnections.forEach((c) => {
      if (c.source) {
        map[c.source.id] = c;
      }
    });
    return map;
  }, [bidirectionalConnections]);

  /**
   * Get a connection by its id.
   * @param id
   */
  const getConnectionById = (id: string): ResolvedConnection | undefined => {
    return resolvedConnections.find((c) => c.id === id);
  };

  return {
    connectionPoints,
    connectionPointsById,
    connections: resolvedConnections,
    bidirectionalConnections,
    connectionsById,
    connectionsByConductorId,
    connectionsBySourceId,
    getConnectionById,
    createConnection,
    updateConnection,
    deleteConnection,
    isLoading,
    isSuccess,
    error,
  };
};
