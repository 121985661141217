import { LayoutNodeType } from '@senrasystems/senra-ui';
import { useLayoutData } from '@web/apps/Design/hooks/useLayoutData.tsx';
import { useState } from 'react';
import { useEffect } from 'react';

import { setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';

export const useLoadRelationalLayout = (setNodes: setNodesType, setEdges: setEdgesType) => {
  const [layoutLoaded, setLayoutLoaded] = useState(false);
  const { layoutNodes, layoutEdges, isSuccess: isLayoutDataLoaded } = useLayoutData();

  useEffect(() => {
    const loadLayout = async () => {
      if (isLayoutDataLoaded && layoutNodes && layoutEdges) {
        setNodes(
          layoutNodes.map((node) => ({
            id: node.id,
            position: { x: node.x, y: node.y },
            type: node.type === LayoutNodeType.DESIGN_PART_NODE ? node.designPartType : node.type,
            data: {
              designPartId: node.designPartId,
              rotateLock: node.rotateLocked,
              angle: node.angle,
            },
          })),
        );
        setEdges(
          layoutEdges.map((edge) => ({
            id: edge.id,
            type: edge.type,
            source: edge.sourceId,
            target: edge.targetId,
            data: {
              distanceFromEdge: edge.distanceFromEdge,
              overwraps: [],
              bundles: [],
              measurement: edge.measurement,
              flipped: edge.flipped,
            },
          })),
        );
        setLayoutLoaded(true);
      }
    };

    void loadLayout();
  }, [layoutLoaded, layoutNodes, layoutEdges, setNodes, setEdges, isLayoutDataLoaded]);

  return layoutLoaded;
};
