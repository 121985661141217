import { Box, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import { PartType } from '@senrasystems/senra-ui';
import { useMemo, useState } from 'react';

import { usePartSearch } from '../../hooks/usePartSearch.tsx';
import { PartSearchModalProps } from '../../hooks/usePartSearchModal.tsx';
import { PartSearchFilters } from './components/PartSearchFilters.tsx';
import { PartSearchInput } from './components/PartSearchInput.tsx';
import PartSearchList from './components/PartSearchList.tsx';

/**
 * PartsSearchModal component displays a modal for searching parts.
 * The search is debounced by 500 ms.
 * @constructor
 */
const PartSearchModalContent = ({
  initialPartTypeFilters,
  filtersDisabled,
  renderSearchResultAction,
}: PartSearchModalProps) => {
  // State to manage what part is expanded in the search results
  const [expandedPartId, setExpandedPartId] = useState<string | null>(null);

  // State for the input value (find part input)
  const [inputValue, setInputValue] = useState('');

  const setSearchValue = (value: string = '') => {
    setInputValue(value);
    setExpandedPartId(null);
  };

  // Custom hook for finding parts
  const { parts, filters, setFilters } = usePartSearch({
    searchTerm: inputValue,
    initialFilters: initialPartTypeFilters,
    designToolSearch: true,
  });

  const firstFilter = useMemo(() => filters[0] ?? PartType.BACKSHELL, [filters]);

  return (
    <ModalContent w="800px" bg="gray.100">
      <ModalBody w="full" p={0}>
        <VStack align="stretch">
          <Box px={4} pt={4} borderRadius="md">
            <PartSearchInput value={inputValue} onChange={setSearchValue} />
          </Box>
          <Box px={4} pb={2} ml="auto">
            <PartSearchFilters
              selectedOptions={initialPartTypeFilters}
              filtersDisabled={filtersDisabled}
              onChangeFilter={setFilters}
            />
          </Box>
          <Box
            maxH="calc(100vh - 200px)"
            overflowY="auto"
            w="full"
            borderTop={parts.length > 0 ? '1px solid' : '0px'}
            borderColor="gray.300"
          >
            <PartSearchList
              parts={parts}
              setSearchValue={setSearchValue}
              expandedPartId={expandedPartId}
              setExpandedPartId={setExpandedPartId}
              filterType={firstFilter}
              renderSearchResultAction={renderSearchResultAction}
            />
          </Box>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};

export default PartSearchModalContent;
