import { LayoutEdge, UUID } from '@senrasystems/senra-ui';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { doRequest, getUrl } from '@web/common/api.ts';

// API Calls
const updateLayoutEdge = async (designId: UUID, layoutEdgeId: UUID, data: Partial<LayoutEdge>) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_edges/${layoutEdgeId}`);
  return await doRequest<LayoutEdge>('PUT', url, JSON.stringify({ layoutEdge: data }));
};

// React Query Hook: useUpdateLayoutNodeMutation
interface UpdateLayoutEdgeParams {
  designId: UUID;
  layoutEdgeId: UUID;
  data: Partial<LayoutEdge>;
}

export const useUpdateLayoutEdgeMutation = (): UseMutationResult<
  LayoutEdge | undefined,
  Error,
  UpdateLayoutEdgeParams
> => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationKey: ['update-layout-edge'],
    mutationFn: async ({ designId, layoutEdgeId, data }: UpdateLayoutEdgeParams) =>
      updateLayoutEdge(designId, layoutEdgeId, data),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => console.error('Error updating layout edge', error.message),
  });
};
