import { UUID } from '@senrasystems/senra-ui';

/**
 * Blog posts on query keys and queries for reference:
 * - https://tkdodo.eu/blog/effective-react-query-keys
 * - https://tkdodo.eu/blog/react-query-data-transformations
 */

// Design Query Keys
export const designKeys = {
  all: ['designs'] as const,
  lists: () => [...designKeys.all, 'list'] as const,
  list: (filters: string[]) => [...designKeys.lists(), { filters }] as const,
  details: () => [...designKeys.all, 'detail'] as const,
  detail: (id: UUID) => [...designKeys.details(), id] as const,
  cutlist: (id: UUID) => [...designKeys.all, 'cutlist', id] as const,
  schematic: (id: UUID, designPartId?: UUID) =>
    [...designKeys.all, 'schematic', id, ...(designPartId ? [designPartId] : [])] as const,
};

// Part Query Keys
export const partKeys = {
  all: ['parts'] as const,
  lists: () => [...partKeys.all, 'list'] as const,
  list: (filters: string[]) => [...partKeys.lists(), { filters }] as const,
  details: () => [...partKeys.all, 'detail'] as const,
  detail: (id: UUID) => [...partKeys.details(), id] as const,
};
