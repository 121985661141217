import { BuildNote, NoteType, UUID } from '@senrasystems/senra-ui';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { getUrl } from '@web/common/api.ts';

// API Calls
const createBuildNote = async (designId: UUID, body: string, type: NoteType) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes`);
  return await doRequest<BuildNote>('POST', url, JSON.stringify({ buildNote: { body, type } }));
};

const updateBuildNote = async (designId: UUID, noteId: UUID, buildNote: Partial<BuildNote>) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes/${noteId}`);
  return await doRequest<BuildNote>('PUT', url, JSON.stringify({ buildNote }));
};

const deleteBuildNote = async (designId: UUID, noteId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/build_notes/${noteId}`);
  await doRequest('DELETE', url);
};

// React Query Hook: useCreateBuildNoteMutation
interface CreateBuildNoteParams {
  designId: UUID;
  body: string;
  type: NoteType;
}

export const useCreateBuildNoteMutation = (): UseMutationResult<
  BuildNote | undefined,
  Error,
  CreateBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-build-note'],
    mutationFn: async ({ designId, body, type }: CreateBuildNoteParams) => createBuildNote(designId, body, type),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Creating Build Note', error.message),
  });
};

// React Query Hook: useUpdateBuildNoteMutation
interface UpdateBuildNoteParams {
  designId: UUID;
  noteId: UUID;
  buildNote: Partial<BuildNote>;
}

export const useUpdateBuildNoteMutation = (): UseMutationResult<
  BuildNote | undefined,
  Error,
  UpdateBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-build-note'],
    mutationFn: async ({ designId, noteId, buildNote }: UpdateBuildNoteParams) =>
      updateBuildNote(designId, noteId, buildNote),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Updating Build Note', error.message),
  });
};

// React Query Hook: useDeleteBuildNoteMutation
interface DeleteBuildNoteParams {
  designId: UUID;
  noteId: UUID;
}

export const useDeleteBuildNoteMutation = (): UseMutationResult<void, Error, DeleteBuildNoteParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-build-note'],
    mutationFn: async ({ designId, noteId }: DeleteBuildNoteParams) => deleteBuildNote(designId, noteId),
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Deleting Build Note', error.message),
  });
};
