import { useUpdateLayoutNodeMutation } from '@web/apps/Design/api/layout-nodes-api.ts';
import { Edge, Node } from '@xyflow/react';
import { MouseEvent, useState } from 'react';

import { SELECTION_TYPES, useDesign } from '../../../hooks/useDesign.tsx';
import { defaultLayoutConfig } from '../config.ts';
import { isBreakoutPointNode, isDesignPartNode, isMeasurementEdge, isSegmentEdge } from '../types.ts';
import { useLayoutActions } from './actions/useLayoutActions.tsx';

/**
 * Hook to manage ReactFlow events and context menus.
 */
export const useRelationalLayout = () => {
  // Settings for the layout
  const [config, setConfig] = useState(defaultLayoutConfig);

  const { designId, isViewOnly, setSelection, clearSelections } = useDesign();
  const { closeAllMenus, onGraphElementContextMenu, onPaneContextMenu, contextMenus } = useLayoutActions();
  const { mutate: updateLayoutNode } = useUpdateLayoutNodeMutation();

  const onPaneClick = (event: MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    clearSelections();
  };

  const onNodeClick = (_event: MouseEvent, node: Node) => {
    console.debug('Node clicked:', node);
    setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, node.id);
    if (isDesignPartNode(node)) {
      setSelection(SELECTION_TYPES.DESIGN_PART, node.data.designPartId);
    }
  };

  const onEdgeClick = (_event: MouseEvent, edge: Edge) => {
    console.debug('Edge clicked:', edge);
    if (isSegmentEdge(edge) || isMeasurementEdge(edge)) {
      setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, edge.id, true);
    }
  };

  const onNodeDrag = (_event: MouseEvent, node: Node) => {
    closeAllMenus();
    if (isViewOnly || !isBreakoutPointNode(node)) {
      return;
    }
  };

  const onNodeDragStop = (_event: MouseEvent, node: Node) => {
    if (isViewOnly) {
      return;
    }

    updateLayoutNode({
      designId: designId,
      layoutNodeId: node.id,
      data: {
        x: node.position.x,
        y: node.position.y,
      },
    });
  };

  return {
    config,
    setConfig,
    onPaneContextMenu,
    onGraphElementContextMenu,
    closeAllMenus,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  };
};
