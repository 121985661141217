import { BaseEdge, Edge, EdgeProps, getSmoothStepPath, Position, useReactFlow } from '@xyflow/react';

import { defaultLayoutConfig } from '../../../config.ts';
import { defaultDirection, getTargetNodeDirection } from '../../../utils/getDirection.ts';

export type PartNoteEdgeType = Edge;

// Define the style for the segment
const segmentStyle = {
  stroke: defaultLayoutConfig.edgeColor,
  strokeWidth: 1,
};

/**
 * Note edge component connecting the flag notes to the indicated element.
 * @constructor
 * @param props
 */
export const PartNoteEdge = ({ source, target, sourceX, sourceY, targetX, targetY }: EdgeProps<PartNoteEdgeType>) => {
  const { getNode } = useReactFlow();
  const sourceNode = getNode(source);
  const targetNode = getNode(target);

  const { horizontal, vertical } =
    sourceNode && targetNode ? getTargetNodeDirection(sourceNode, targetNode) : defaultDirection;

  const isTargetBelow = vertical === Position.Bottom;
  const targetPosition = isTargetBelow ? Position.Top : Position.Bottom;
  const offsetY = isTargetBelow ? -25 : 30;

  // Get the edge path for the note edge
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: horizontal,
    targetX,
    targetY: targetY + offsetY,
    targetPosition,
  });

  return <BaseEdge path={edgePath} markerEnd={'url(#flag-note-arrow)'} style={segmentStyle} />;
};
