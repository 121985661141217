import { Design, LayoutData, LayoutEdge, LayoutNode } from '@senrasystems/senra-ui';
import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';

import { defaultViewport } from '../../types/reactFlow.ts';
import { useDesignId } from './useDesignId.tsx';

// Define the return type
interface UseLayoutDataResult {
  layoutData: LayoutData;
  layoutNodes: LayoutNode[];
  layoutEdges: LayoutEdge[];
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
}

// Define a default layout data object
const defaultLayoutData: LayoutData = {
  nodes: [],
  edges: [],
  viewport: defaultViewport,
};

// Define the select function
const selectLayoutData = (design: Design | undefined) => ({
  layoutData: design?.layoutData || defaultLayoutData,
  layoutNodes: design?.layoutNodes || [],
  layoutEdges: design?.layoutEdges || [],
});

/**
 * Hook to get layout data by design ID. Fetches layoutData using useDesignQuery. This hook uses the select function of
 * useDesignQuery to only subscribe to layoutData.
 */
export const useLayoutData = (): UseLayoutDataResult => {
  const designId = useDesignId();

  // Fetch layoutData
  const { data, isLoading, isSuccess, error } = useDesignQuery(designId, selectLayoutData);

  return {
    layoutData: data?.layoutData || defaultLayoutData,
    layoutNodes: data?.layoutNodes || [],
    layoutEdges: data?.layoutEdges || [],
    isLoading,
    isSuccess,
    error,
  };
};
