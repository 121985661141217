import { Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import { Node, NodeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../../config.ts';
import { SchematicConnectionGroup } from '../../../types/SchematicConnectionGroup.ts';
import { Cavity } from './Cavity.tsx';
import { Conductor } from './Conductor.tsx';
import { ConnectorLabel } from './ConnectorLabel.tsx';

export type SchematicNodeData = {
  connectionGroups: SchematicConnectionGroup[];
  width: number;
};

export type SchematicNodeType = Node<SchematicNodeData>;

/**
 * Schematic node component. This component has 3 columns: source cavities, conductors, and destination cavities. Each
 * column has a vertical stack of cavities or conductors. Each row is the same height so that the cavities and conductors
 * line up. The cavities on the left can only appear once. The cavities on the right can only appear once per group.
 * @param props
 * @constructor
 */
export const SchematicNode = ({ data }: NodeProps<SchematicNodeType>) => {
  return (
    <VStack w={data.width} spacing={0} p={8}>
      {data.connectionGroups.map((group, index) => {
        const showDestination = group.sourcePart.id === group.destinationPart.id;

        return (
          <VStack key={group.destinationPart.id} w="full" spacing={0}>
            {/* For each group of connections, label connectors, as needed */}
            <HStack w="full" align="stretch">
              {index === 0 ? (
                <ConnectorLabel part={group.sourcePart} isSource={true} />
              ) : (
                <Box bg={defaultSchematicConfig.connectorColor} display="flex" alignItems="center" px={4} py={2}>
                  <Cavity point={null} isSource={false} visibility="hidden" />
                </Box>
              )}
              <Spacer />
              <ConnectorLabel
                part={group.destinationPart}
                isSource={false}
                visibility={showDestination ? 'hidden' : 'visible'}
              />
            </HStack>
            <HStack w="full" align="stretch">
              {/* Left vertical container for source cavities */}
              <VStack
                bg={defaultSchematicConfig.connectorColor}
                spacing={0}
                borderTopRadius={index === 0 ? 'md' : 'none'}
                borderBottomRadius={index === Object.keys(data.connectionGroups).length - 1 ? 'md' : 'none'}
                py={2}
              >
                {group.rows.map((row, index) => (
                  <Box key={index} display="flex" alignItems="center" px={4} py={2}>
                    <Cavity
                      point={row.sourcePoint}
                      isSource={true}
                      visibility={row.sourcePoint.isVisible ? 'visible' : 'hidden'}
                    />
                  </Box>
                ))}
              </VStack>
              <Spacer />
              {/* Middle vertical container for conductors */}
              <VStack w="75%" spacing={0} py={2}>
                {group.rows.map((row, index) => (
                  <Box key={index} w="full" display="flex" alignItems="center" py={2}>
                    <Conductor conductor={row.conductor} />
                  </Box>
                ))}
              </VStack>
              <Spacer />
              {/* Right vertical container for destination cavities */}
              <VStack
                h="full"
                spacing={0}
                bg={defaultSchematicConfig.connectorColor}
                borderRadius="md"
                py={2}
                visibility={showDestination ? 'hidden' : 'visible'}
              >
                {group.rows.map((row, index) => (
                  <Box
                    key={index}
                    visibility={row.destinationPoint.isVisible && !showDestination ? 'visible' : 'hidden'}
                    alignItems="center"
                    px={4}
                    py={2}
                  >
                    <Cavity point={row.destinationPoint} isSource={false} />
                  </Box>
                ))}
              </VStack>
            </HStack>
          </VStack>
        );
      })}
    </VStack>
  );
};
