import { Flex } from '@chakra-ui/react';
import { ReactFlowProvider } from '@xyflow/react';

import { ContextPanelWithTabs } from '../../components/ContextPanel';
import WiringList from '../WiringList/WiringList.tsx';
import Schematic from './Schematic.tsx';

const tabs = [{ value: 'wiring', headerLabel: 'Wiring List', content: <WiringList /> }];

/**
 * Schematic component displays a schematic view of the selected design part's connections.
 * @constructor
 */
const SchematicWithDrawer = () => {
  return (
    <Flex flexDirection="column" flex={1}>
      <ReactFlowProvider>
        <Schematic />
        <ContextPanelWithTabs tabs={tabs} />
      </ReactFlowProvider>
    </Flex>
  );
};

export default SchematicWithDrawer;
