import { isConnectionTarget, ResolvedConnection, ResolvedConnectionPoint, UUID } from '@senrasystems/senra-ui';
import { useMemo } from 'react';

import { Option } from '../../../components/SearchableDropdown.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';

export interface UseConnectionsData {
  connectionPoints: ResolvedConnectionPoint[];
  connections: ResolvedConnection[];
}

/**
 * useDestinationOptions hook returns a list of destination options for a given connection point. Valid destinations are
 * connection points that are connection targets and are different from the source connection point. The list is sorted
 * by disabled status.
 * @param connectionPoint
 * @param _currentValue
 * @param injectedData
 */
export const useDestinationOptions = (
  connectionPoint: ResolvedConnectionPoint,
  _currentValue?: UUID,
  injectedData?: UseConnectionsData,
) => {
  const { connectionPoints, connections } = useConnections();

  const data = useMemo(
    () => injectedData || { connections, connectionPoints },
    [connectionPoints, connections, injectedData],
  );

  const groupedOptions = useMemo(() => {
    // Create a set of used cavity IDs from connectionsBySourceId
    const usedCavityIds = new Set();

    connections.forEach((conn) => {
      usedCavityIds.add(conn.source?.id);
      usedCavityIds.add(conn.destination?.id);
    });

    // Separate connection points into used and empty cavities
    const emptyCavities: Option[] = [];
    const usedCavities: Option[] = [];
    const loopbackCavities: Option[] = [];

    data.connectionPoints
      .filter((cp) => isConnectionTarget(cp.designPart) && cp.id !== connectionPoint.id)
      .forEach((cp) => {
        const option: Option = {
          label: cp.displayName,
          value: cp.id,
        };

        if (usedCavityIds.has(cp.id)) {
          usedCavities.push({ ...option, colorScheme: 'red' });
          if (cp.designPart.id === connectionPoint.designPart.id) {
            loopbackCavities.push(option);
          }
        } else {
          emptyCavities.push(option);
          if (cp.designPart.id === connectionPoint.designPart.id) {
            loopbackCavities.push(option);
          }
        }
      });

    // Return grouped options
    return [
      {
        label: 'Unused',
        options: emptyCavities,
      },
      {
        label: 'Loopback',
        options: loopbackCavities,
      },
      {
        label: 'Used',
        options: usedCavities,
      },
    ];
  }, [connections, data.connectionPoints, connectionPoint.id, connectionPoint.designPart.id]);

  return { options: groupedOptions };
};
