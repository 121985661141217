import { ToastId } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { useMutation } from '@tanstack/react-query';
import { useDesignToast } from '@web/apps/Design/hooks/useDesignToast.tsx';
import { downloadFile, getBaseOptions, getUrl } from '@web/common/api.ts';
import { useRef } from 'react';

export type Extension = 'pdf' | 'xlsx';

// API Calls
const getExportURL = (designId: UUID, extension: Extension) => getUrl(`/api/v1/designs/${designId}.${extension}`);

const fetchExport = async (designId: UUID, extension: Extension) => {
  const url = getExportURL(designId, extension);

  const options = await getBaseOptions('GET');
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error('Failed to download export');
  }

  return response.blob();
};

// React Query Hook: useExportDesignMutation
interface ExportDesignParams {
  designId: UUID;
  partNumber: string;
  partRevision: string;
  extension: Extension;
}

export const useExportDesignMutation = () => {
  const { showLoadingToast, showErrorToast, closeToast } = useDesignToast();
  const toastIdRef = useRef<ToastId | undefined>(undefined);

  return useMutation({
    mutationKey: [`export-design`],
    mutationFn: async ({ designId, partNumber, partRevision, extension }: ExportDesignParams) => {
      const filename = `design-${partNumber}${partRevision ? `_${partRevision}` : ''}.${extension}`;
      const blob = await fetchExport(designId, extension);
      downloadFile(blob, filename);
    },
    onMutate: ({ extension }: ExportDesignParams) => {
      toastIdRef.current = showLoadingToast('Exporting Design', `Exporting design to .${extension}`);
    },
    onSuccess: () => {
      if (toastIdRef.current) {
        closeToast(toastIdRef.current);
      }
    },
    onError: (error) => {
      if (toastIdRef.current) {
        closeToast(toastIdRef.current);
      }
      showErrorToast('Download Failed', error.message);
    },
  });
};
