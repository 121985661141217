import {
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { InsertArrangement } from '@senrasystems/senra-ui';
import { DEFAULT_PARTS_PAGE_SIZE } from '@web/api/parts-api.ts';
import generateURLSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import { successToast } from '@web/common/toasts.ts';
import DashboardRowActions from '@web/components/DashboardRowActions.tsx';
import FilterBox from '@web/components/FilterBox.tsx';
import Loading from '@web/components/Loading.tsx';
import Paginate from '@web/components/Paginate.tsx';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Title from '../../../../components/Title.tsx';
import { useDeleteInsertArrangement, useInsertArrangement, useInsertArrangements } from '../../api/queries.ts';
import InsertArrangementModal, { Mode } from './InsertArrangementModal.tsx';

const InsertArrangementsDashboard = () => {
  //styling
  const fontWeight = { header: '500', row: 'normal' };

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const insertArrangementName = searchParams.get('q') ?? '';
  const { insertArrangementId } = useParams<{ insertArrangementId: string }>();
  const { data: insertArrangement } = useInsertArrangement(insertArrangementId);
  const debouncedInsertArrangementName = useDebouncedValue(insertArrangementName, 250);
  const { isLoading, data: insertArrangements } = useInsertArrangements(
    debouncedInsertArrangementName as string,
    page,
    DEFAULT_PARTS_PAGE_SIZE,
  );
  const deleteInsertArrangement = useDeleteInsertArrangement();

  const navigate = useNavigate();
  const [mode, setMode] = useState(Mode.CREATE);

  const formMethods = useForm<InsertArrangement>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAdd = () => {
    formMethods.reset({ name: '', cavities: [] });
    setMode(Mode.CREATE);
    onOpen();
  };

  const handleClose = () => {
    navigate(`/parts/insert-arrangements${generateURLSearch({ page, q: insertArrangementName })}`);
    formMethods.reset({ name: insertArrangementName, cavities: [] });
    setMode(Mode.READ);
    onClose();
  };

  const handleView = useCallback(
    (insertArrangement: InsertArrangement) => {
      navigate(
        `/parts/insert-arrangements/${insertArrangement.id}${generateURLSearch({
          mode: Mode.READ,
          q: insertArrangementName,
        })}`,
      );
    },
    [navigate, insertArrangementName],
  );

  const handleEdit = useCallback(
    (insertArrangement: InsertArrangement) => {
      navigate(
        `/parts/insert-arrangements/${insertArrangement.id}${generateURLSearch({
          mode: Mode.READ,
          q: insertArrangementName,
        })}`,
      );
    },
    [navigate, insertArrangementName],
  );

  useEffect(() => {
    if (insertArrangement) {
      const mode = searchParams.get('mode') as Mode | null;

      formMethods.reset(insertArrangement);
      setMode(mode ?? Mode.READ);
      onOpen();
    }
  }, [formMethods, insertArrangement, onOpen, searchParams]);

  const handleDelete = async (insertArrangementId: string) => {
    if (confirm('Are you sure you want to delete this insert arrangement?')) {
      await deleteInsertArrangement.mutateAsync(insertArrangementId);
      successToast('Insert arrangement successfully deleted');
    }
  };

  const handleClone = (insertArrangement: InsertArrangement) => {
    formMethods.reset({
      ...insertArrangement,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      documents: [],
    });
    setMode(Mode.CREATE);
    onOpen();
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('q', value);
    } else {
      searchParams.delete('q');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack direction="column" w="full" h="full" spacing={8}>
        <Spacer />
        <Flex justifyContent={'space-between'} px={8}>
          <Title title="Insert Arrangements" aria-label="page-title" />
          <Button onClick={handleAdd}>+ Add Insert Arrangement</Button>
          <InsertArrangementModal mode={mode} isOpen={isOpen} onClose={handleClose} onDelete={handleDelete} />
        </Flex>
        <HStack spacing={6} paddingX={9} paddingY={0}>
          <FilterBox
            placeholder="Type to filter insert arrangements"
            value={insertArrangementName}
            onChange={handleFilterQuery}
          />
          <Button
            onClick={() => {
              handleFilterQuery('');
            }}
          >
            Clear filters
          </Button>
        </HStack>
        <HStack alignItems="flex-start" spacing={0} h="full">
          <Flex width="100%" height="100%" overflowY="auto">
            {isLoading ? (
              <Center height="50vh" width="80vw">
                <Loading message="Loading insert arrangements…" />
              </Center>
            ) : (
              <VStack w="full">
                <TableContainer w="full">
                  <Table layout="auto" width={'100%'}>
                    <Thead fontWeight={fontWeight.header} borderColor={'gray.200'}>
                      <Tr bg={'gray.100'}>
                        <Th w="70%">Arrangement Name</Th>
                        <Th w="30%">Number of Cavities</Th>
                        <Th w="10px" textAlign="right">
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody color={'g'} fontWeight={fontWeight.row}>
                      {insertArrangements?.data.map((insertArrangement) => (
                        <Tr
                          key={insertArrangement.id}
                          role="group"
                          _hover={{ bg: 'gray.50' }}
                          onClick={() => handleView(insertArrangement)}
                        >
                          <Td minWidth="200px">{insertArrangement.name}</Td>
                          <Td minWidth="200px">{insertArrangement.cavities.length}</Td>
                          <Td minWidth="100px" textAlign="right">
                            <DashboardRowActions
                              item={insertArrangement}
                              onView={handleView}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                              onClone={handleClone}
                              onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Paginate
                  onPageChange={handlePageClick}
                  selectedPage={parseInt(page) - 1}
                  pageCount={insertArrangements?.meta.totalPages as number}
                />
              </VStack>
            )}
          </Flex>
        </HStack>
      </Stack>
    </FormProvider>
  );
};

export default InsertArrangementsDashboard;
