export const RouteNames = {
  ROOT: '/',
  NOT_FOUND: '/404',
  REGISTER: '/register/:userId',
  SIGN_OUT: '/signout',
  PROFILE: '/profile',
  COMPANY_PROFILE: '/company/profile',
  PARTS: {
    INDEX: '/parts',
    BACKSHELL: '/parts/backshells/:partId',
    BACKSHELLS: '/parts/backshells',
    CABLE: '/parts/cables/:partId',
    CABLES: '/parts/cables',
    CONNECTOR: '/parts/connectors/:partId',
    CONNECTORS: '/parts/connectors',
    CONTACT: '/parts/contacts/:partId',
    CONTACTS: '/parts/contacts',
    INSERT_ARRANGEMENT: '/parts/insert-arrangements/:insertArrangementId',
    INSERT_ARRANGEMENTS: '/parts/insert-arrangements',
    GENERIC: '/parts/generic/:partId',
    GENERICS: '/parts/generic',
    OVERWRAP: '/parts/overwraps/:partId',
    OVERWRAPS: '/parts/overwraps',
    PASSIVE: '/parts/passives/:partId',
    PASSIVES: '/parts/passives',
    PIGTAIL: '/parts/pigtails/:partId',
    PIGTAILS: '/parts/pigtails',
    SPLICE: '/parts/splices/:partId',
    SPLICES: '/parts/splices',
    WIRE: '/parts/wires/:partId',
    WIRES: '/parts/wires',
  },
  TOOLS: {
    INDEX: '/tools',
    TORQUE_ADAPTERS: '/tools/torque-adapters',
    CRIMPERS: '/tools/crimpers',
    POSITIONERS: '/tools/positioners',
    DIE_SETS: '/tools/die-sets',
    TURRET_HEADS: '/tools/turret-heads',
    INSERTIONS_REMOVALS: '/tools/insertions-removals',
  },
  DESIGNS: {
    INDEX: '/',
    DESIGNS: '/designs',
    DESIGN: '/designs/:designId',
    DESIGN_PART: '/designs/:designId/design-parts/:designPartId',
    DESIGN_PARTS: '/designs/:designId/design-parts',
    BOM: '/designs/:designId/bom',
    WIRING_LIST: '/designs/:designId/wiring-list',
    LAYOUT: '/design/:designId/layout',
    SCHEMATIC: '/design/:designId/schematic',
    CUTLIST: '/design/:designId/cutlist',
  },
  TENANTS: {
    INDEX: '/tenants',
    NEW: '/tenants/new',
    EDIT: '/tenants/:tenantId/edit',
  },
  USERS: {
    INDEX: '/users',
    NEW: '/users/new',
    EDIT: '/users/:userId/edit',
  },
};
