import { Edge, Node, Position } from '@xyflow/react';

import { calculateMidpoint, getDirectionToParentNode, getTargetDirection } from './geometry';

export const defaultDirection = { horizontal: Position.Right, vertical: Position.Top };

export const getTargetNodeDirection = (sourceNode: Node, targetNode: Node) => {
  return sourceNode.parentId
    ? getDirectionToParentNode(sourceNode.position, targetNode)
    : getTargetDirection(sourceNode.position, targetNode.position);
};

export const getTargetSegmentDirection = (
  sourceNode: Node,
  targetEdge: Edge,
  getNode: (id: string) => Node | undefined,
) => {
  const edgeSourceNode = getNode(targetEdge.source);
  const edgeTargetNode = getNode(targetEdge.target);

  if (edgeSourceNode && edgeTargetNode) {
    const midpoint = calculateMidpoint(edgeSourceNode.position, edgeTargetNode.position);
    return getTargetDirection(sourceNode.position, midpoint);
  }

  return defaultDirection;
};
