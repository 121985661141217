import { Connector, Part, PartGender, PartType, Passive, UUID } from '@senrasystems/senra-ui';
import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';

/**
 * Hook to suggest a part name for a new part in a design.
 * @param designId
 */
export const useSuggestPartName = (designId: UUID) => {
  const { data: design, isLoading, error } = useDesignQuery(designId);

  const getPrefix = (part: Part) => {
    switch (part.type) {
      case PartType.CABLE:
        return 'C';
      case PartType.CONNECTOR: {
        const connector = part as Connector;
        return connector.gender === PartGender.FEMALE ? 'J' : 'P';
      }
      case PartType.GENERIC:
        return 'GP';
      case PartType.PASSIVE:
        return (part as Passive).subtype?.charAt(0) ?? 'PSV';
      case PartType.PIGTAIL:
        return 'PT';
      case PartType.SPLICE:
        return 'SPL';
      case PartType.WIRE:
        return 'W';
      default:
        return part.type.toUpperCase();
    }
  };

  const suggestPartName = (part: Part) => {
    if (!design) {
      return '';
    }
    // Get the prefix for the part type
    const prefix = getPrefix(part);
    // Find all parts of the same type
    const designParts = design.designParts.filter((p) => p.partData.type === part.type);
    // Starting from 1, find the first number that is not in use
    let i = 1;
    while (designParts.some((p) => p.name === `${prefix}${i}`)) {
      i++;
    }
    return `${prefix}${i}`;
  };

  return { suggestPartName, isLoading, error };
};
