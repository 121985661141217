import { UUID } from '@senrasystems/senra-ui';
import { Position, useReactFlow } from '@xyflow/react';

import { defaultDirection, getTargetNodeDirection, getTargetSegmentDirection } from '../../utils/getDirection';

// Returns horizontal and vertical direction from source to target
export const useCalculateTargetDirection = (sourceNodeId: UUID, targetElementId?: UUID) => {
  const { getNode, getEdge } = useReactFlow();

  if (!targetElementId) {
    return defaultDirection;
  }

  const sourceNode = getNode(sourceNodeId);
  const targetNode = getNode(targetElementId);
  const targetEdge = getEdge(targetElementId);

  if (sourceNode) {
    if (targetNode) {
      return getTargetNodeDirection(sourceNode, targetNode);
    } else if (targetEdge) {
      return getTargetSegmentDirection(sourceNode, targetEdge, getNode);
    }
  }

  return { horizontal: Position.Right, vertical: Position.Top };
};
