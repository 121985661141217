import { Flex, Text } from '@chakra-ui/react';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import React from 'react';

import { useDesign } from '../../../../hooks/useDesign.tsx';
import { edgeTypes, nodeTypes } from '../../types.ts';
import { DesignPartView } from './DesignPartView.tsx';
import { MeasurementView } from './MeasurementView.tsx';
import { SegmentView } from './SegmentView/SegmentView.tsx';

type NodeType = keyof typeof nodeTypes;
type EdgeType = keyof typeof edgeTypes;
type LayoutElementType = NodeType | EdgeType;

const componentMap: Partial<Record<LayoutElementType, React.FC<LayoutElementViewProps>>> = {
  // Node Types
  Connector: DesignPartView,
  Passive: DesignPartView,
  Pigtail: DesignPartView,
  Splice: DesignPartView,
  // Edge Types
  Segment: SegmentView,
  Measurement: MeasurementView,
} as const;

type LayoutElementViewProps = {
  node?: Node;
  edge?: Edge;
};

export const DetailsView = () => {
  const { selections } = useDesign();
  const { getNode, getEdge } = useReactFlow();

  const selectedNode = getNode(selections.layoutElement || '');
  const selectedEdge = getEdge(selections.layoutElement || '');
  const selectedElement = selectedNode || selectedEdge;

  const Component = selectedElement?.type ? componentMap[selectedElement.type as keyof typeof componentMap] : undefined;

  if (!Component) {
    return <Text p={4}>Select a valid target to show details.</Text>;
  }

  return (
    <Flex w="full" flexDirection="column" overflow="auto">
      <Component node={selectedNode} edge={selectedEdge} />
    </Flex>
  );
};
