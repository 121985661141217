import { Text } from '@chakra-ui/react';

import SearchableDropdown, { GroupedOption, Option } from '../../../components/SearchableDropdown.tsx';

interface Props {
  isEditing: boolean;
  value: string;
  options: Option[] | GroupedOption[];
  onChange: (value: string) => void;
}

export const ConnectionItemDropdown = ({ isEditing, value, options, onChange }: Props) => {
  const findSelectedOption = (value: string): Option | null => {
    if (Array.isArray(options) && options.length > 0) {
      if ('options' in options[0]) {
        // Handle grouped options
        for (const group of options as GroupedOption[]) {
          const found = group.options.find((opt) => opt.value === value);
          if (found) {
            return found;
          }
        }
      } else {
        // Handle flat options
        return (options as Option[]).find((opt) => opt.value === value) || null;
      }
    }
    return null;
  };

  const selectedOption = findSelectedOption(value);

  return isEditing ? (
    <SearchableDropdown
      options={options || []}
      value={selectedOption}
      onChange={(option) => onChange(option ? option.value : '')}
    />
  ) : (
    <Text>{selectedOption?.label}</Text>
  );
};
