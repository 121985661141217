import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';

import { defaultViewport, setEdgesType, setNodesType } from '../../../../types/reactFlow.ts';
import { useSchematicQuery } from '../../../api/schematic-api.ts';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import { defaultSchematicConfig } from '../config.ts';

export interface UseLoadSchematic {
  schematicLoaded: boolean;
  updateSchematicWidth: (width: number) => void;
}

export const useLoadSchematic = (setNodes: setNodesType, setEdges: setEdgesType): UseLoadSchematic => {
  const designId = useDesignId();
  const [schematicLoaded, setSchematicLoaded] = useState(false);
  const [width, setWidth] = useState(defaultSchematicConfig.width);
  const { setViewport } = useReactFlow();

  const selectedDesignPart = useSelectedDesignPart();
  const { data: schematicData, isSuccess: isSchematicLoaded } = useSchematicQuery(
    designId,
    selectedDesignPart?.id || '',
  );

  // Provide a function to update the schematic width, which will trigger a reload
  const updateSchematicWidth = useCallback((width: number) => {
    setSchematicLoaded(false);
    setWidth(width);
  }, []);

  // Reset schematic loaded state when the selected design part changes, or when the schematic data changes
  useEffect(() => {
    setSchematicLoaded(false);
  }, [schematicData, selectedDesignPart]);

  // Initialize schematic
  useEffect(() => {
    const loadSchematic = async () => {
      if (!selectedDesignPart || schematicLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isSchematicLoaded && schematicData) {
        console.debug('Graph:', schematicData);
        schematicData.nodes[0].data.width = width;
        setNodes(schematicData.nodes);
        setEdges(schematicData.edges);
        await setViewport(defaultViewport);

        setSchematicLoaded(true);
      }
    };

    void loadSchematic();
  }, [isSchematicLoaded, schematicData, schematicLoaded, selectedDesignPart, setEdges, setNodes, setViewport, width]);

  return { schematicLoaded, updateSchematicWidth };
};
