import {
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { Part, PartType, UUID } from '@senrasystems/senra-ui';
import { ChangeEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PartFields } from '../../../../Parts-Library/index.ts';
import { useCreatePartMutation } from '../../../api/parts-api.ts';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';

interface Props extends AccordionItemProps {
  afterCreateCallback: (part: Part) => void;
  filterType: PartType;
}

const CreatePartButton = ({ afterCreateCallback, filterType, ...rest }: Props) => {
  const { showSuccessToast } = useDesignToast();
  const formMethods = useForm<Part>({ defaultValues: { type: PartType.BACKSHELL } });

  const { mutateAsync } = useCreatePartMutation({ onError: () => formMethods.resetField('status') });

  const resetForm = (type: PartType = PartType.BACKSHELL) => {
    formMethods.reset();
    formMethods.setValue('type', type);
  };

  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const currentValues = formMethods.getValues();
    resetForm(e.target.value as PartType);

    // Preserve common fields when changing part type
    formMethods.setValue('partNumber', currentValues.partNumber);
    formMethods.setValue('manufacturers', currentValues.manufacturers);
    formMethods.setValue('description', currentValues.description);
    formMethods.setValue('confidence', currentValues.confidence);
    formMethods.setValue('documents', currentValues.documents);
  };

  const handleAccordionButtonClick = (isExpanded: boolean) => {
    if (!isExpanded) {
      resetForm(filterType);
    }
  };

  const handleCreatePart = async (data: Part) => {
    const { manufacturers, ...restData } = data;
    const sanitizedData = Object.fromEntries(
      Object.entries(restData).map(([key, value]) => [key, value === null ? undefined : value]),
    );

    const payload: Part & { manufacturerIds: UUID[] } = {
      ...sanitizedData,
      type: formMethods.getValues('type') as PartType,
      manufacturerIds: manufacturers?.map((m) => m.id) || [],
      partNumber: data.partNumber,
      description: data.description,
      confidence: data.confidence,
    };

    const newPart = await mutateAsync({ data: payload });
    if (newPart) {
      afterCreateCallback(newPart);
      showSuccessToast('Success', 'Part created.');
      resetForm();
    }
  };

  return (
    <AccordionItem borderTop="1px solid" borderColor="gray.300" _hover={{ bg: 'gray.50' }} {...rest}>
      {({ isExpanded }) => (
        <Box>
          <Center height="9em">
            <AccordionButton
              as={Button}
              bgColor="gray.200"
              w="150px"
              size="lg"
              onClick={() => handleAccordionButtonClick(isExpanded)}
              role="button"
              aria-label="Create New Part"
            >
              {isExpanded ? 'Cancel' : 'Create New Part'}
            </AccordionButton>
          </Center>
          <AccordionPanel>
            <FormControl mb="20px">
              <FormLabel>Part Type</FormLabel>
              <Select w="200px" size="lg" value={formMethods.getValues('type')} onChange={handleTypeChange}>
                {Object.values(PartType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormProvider {...formMethods}>
              <form
                onSubmit={async (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  await formMethods.handleSubmit(handleCreatePart)(event);
                }}
              >
                <PartFields
                  partType={formMethods.getValues('type')}
                  isEditing={true}
                  isUpdateMode={false}
                  isCreateMode={false}
                  theme="light"
                />
              </form>
            </FormProvider>
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

export default CreatePartButton;
