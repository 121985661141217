import { ToastId, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useDesignToast = () => {
  const toast = useToast();

  const showToast = useCallback(
    (
      status: 'info' | 'warning' | 'success' | 'error' | 'loading',
      title: string,
      description: string,
      isClosable = true,
      duration: number | null | undefined = 5000,
    ) => {
      const toastId = window.crypto.randomUUID();
      toast({
        id: toastId,
        title,
        description,
        status,
        duration: status === 'loading' ? null : duration,
        isClosable,
        position: 'top-right',
      });
      return toastId; // Return the unique ID
    },
    [toast],
  );

  const showErrorToast = (title: string, description: string) => showToast('error', title, description);
  const showSuccessToast = (title: string, description: string) => showToast('success', title, description);
  const showWarningToast = (title: string, description: string) => showToast('warning', title, description);
  const showInfoToast = (title: string, description: string) => showToast('info', title, description);
  const showLoadingToast = (title: string, description: string) =>
    showToast('loading', title, description, false, null);

  const closeToast = useCallback(
    (toastId: ToastId) => {
      toast.close(toastId);
    },
    [toast],
  );

  return {
    showToast,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    showInfoToast,
    showLoadingToast,
    closeToast,
    toast,
  };
};
