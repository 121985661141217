import { StackProps, Text, VStack } from '@chakra-ui/react';

import { SchematicPart } from '../../../types/SchematicConnectionGroup.ts';

interface Props extends StackProps {
  part: SchematicPart;
  isSource: boolean;
}

/**
 * ConnectorLabel component. This component is used to display the name and part number of a design part.
 * @param designPart
 * @param isSource
 * @param rest
 * @constructor
 */
export const ConnectorLabel = ({ part, isSource = true, ...rest }: Props) => {
  return (
    <VStack
      width="full"
      alignItems={isSource ? 'flex-start' : 'flex-end'}
      color="gray.900"
      fontFamily="mono"
      p={2}
      {...rest}
    >
      <Text fontWeight="bold" whiteSpace="nowrap">
        {part.name}
      </Text>
      <Text whiteSpace="nowrap">{part.partNumber}</Text>
    </VStack>
  );
};
